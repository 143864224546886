import React from "react";
import { Link } from "react-scroll";
import "./ImageNavigation.css";

// ייבוא נכון של התמונות
import AirmailShipping from "../../assets/AirmailShipping.jpg";
import SeaShipping from "../../assets/SeaShipping.png";
import CustomsClearance from "../../assets/CustomsClearance.jpg";
import LandTransport from "../../assets/LandTransport.jpg";
import Droppers from "../../assets/Droppers.jpg";
import Project from "../../assets/project.jpg";



const ImageNavigation = () => {
  return (
    <div className="image-navigation" dir="rtl">
      {/* שורה ראשונה */}
      <div className="image-row">
        <div className="image-link">
        <div className="image-card">
  <img src={AirmailShipping} alt="שילוח אווירי" />
  <div className="card-content">

    <Link to="air-shipping" smooth={true} className="btn">
      שילוח אווירי
    </Link>
  </div>
</div>
        </div>


        <div className="image-link">
        <div className="image-card">
          <img src={SeaShipping} alt="שילוח ימי" />
          <div className="card-content">
      
          <Link to="sea-shipping" smooth={true} className="btn">
            שילוח ימי
          </Link>
          </div>
        </div>
        </div>



        <div className="image-link">
        <div className="image-card">
          <img src={LandTransport} alt="שילוח יבשתי" />
          <div className="card-content">
   
          <Link to="land-transport" smooth={true} className="btn">
           הובלות יבשתיות
          </Link>
          </div>
        </div>
        </div>


        <div className="image-link">
        <div className="image-card">
          <img src={ Project} alt="שילוח יבשתי" />
          <div className="card-content">
      
          <Link to="project" smooth={true} className="btn">
          פרויקטים
          </Link>
          </div>
        </div>
        </div>

        
        <div className="image-link">
        <div className="image-card">
          <img src={CustomsClearance} alt="שירותי מכס" />
          <div className="card-content">
      
          <Link to="customs-clearance" smooth={true} className="btn">
            שירותי מכס
          </Link>
        </div>
        </div>
       </div>


        <div className="image-link">
        <div className="image-card">
          <img src={Droppers} alt="לוגיסטיקה" />
          <div className="card-content">
      
          <Link to="droppers" smooth={true} className="btn">
           דרופים
          </Link>
          </div>
        </div>
        </div>
      </div>


    </div>
  );
};

export default ImageNavigation;
