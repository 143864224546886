import { useRef } from "react";
import "./Contact.css";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaLocationCrosshairs, FaLinkedin } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
  const container = useRef();
  useGSAP(
    () => {
      gsap
        .timeline({
          delay: 0.5,
          scrollTrigger: {
            trigger: container.current,
            start: "20% bottom",
            end: "bottom top",
          },
        })
        .fromTo(".form", { x: 50, opacity: 0 }, { x: 0, opacity: 1 })
        .fromTo(
          ".contact__infos",
          { y: 100, opacity: 0 },
          { y: 0, opacity: 1 }
        );
    },
    { scope: container }
  );

  return (
    <section id="contact" ref={container} dir="rtl">
      {/* צד שמאל - תמונה */}
      <div className="contact-image"></div>

      {/* צד ימין - טופס ופרטי קשר */}
      <div className="container">
        {/* Form Section */}
        <form className="form">
          <div className="form__top">
            <h3 className="sub__heading">צרו קשר</h3>
            <p className="muted">
              השאירו פרטים מטה ואנחנו כבר נחזור אליכם.
            </p>
          </div>
          <div className="form__middle">
            <input
              type="text"
              placeholder="שם מלא"
              name="fullName"
              className="control"
            />
            <input
              type="email"
              placeholder="דוא״ל"
              name="email"
              className="control"
            />
            <input
              type="tel"
              placeholder="מספר טלפון"
              name="phoneNumber"
              className="control"
            />
            <textarea
              name="message"
              placeholder="הודעה"
              className="control"
            ></textarea>
          </div>
          <div className="form__bottom">
            <button className="btn primary">שלח הודעה</button>
          </div>
        </form>

        {/* Contact Information Section */}
        <div className="contact__infos">
          {/* Email */}
          <div className="flex contact__info">
            <div className="flex__center icon__container">
              <MdOutlineAlternateEmail />
            </div>
            <div className="details">
              <h4>שלחו לנו אימייל</h4>
              <p>
                <a href="mailto:barcargo24.riki@outlook.com">
                  barcargo24.riki@outlook.com
                </a>
                <br/>
                <a href="mailto:Riki@barcargo.com">
                 Riki@barcargo.com
                </a>
              </p>
            </div>
          </div>

          {/* Address */}
          <div className="flex contact__info">
            <div className="flex__center icon__container">
              <FaLocationCrosshairs />
            </div>
            <div className="details">
              <h4>משרדינו יושבים בכתובת</h4>
              <p>שבטי ישראל 33, ראשון לציון</p>
            </div>
          </div>

          {/* Phone */}
          <div className="flex contact__info">
  <a href="tel:0507350087" className="flex__center icon__container">
    <FiPhoneCall />
  </a>
  <div className="details">
    <h4>חייגו אלינו</h4>
    <p>0507350087</p>
  </div>
</div>


          {/* LinkedIn */}
          <div className="flex contact__info">
            <div className="flex__center icon__container">
              <FaLinkedin />
            </div>
            <div className="details">
              <h4>LinkedIn</h4>
              <p>
                <a
                  href="https://www.linkedin.com/in/bar-cargo-4a54a5239"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  linkedin.com/in/bar-cargo-4a54a5239
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
