import React from "react";
import { Link } from "react-scroll";
import "./LandTransport.css";
import LandTransportImage from "../../assets/LandTransport.jpg";

const LandTransport = () => {
  return (
    <div id="land-transport" className="overlay">
      <div className="container">
        <div className="land-transport-section">
          <div className="land-transport-container">
            <div className="land-transport-content"  dir="rtl">
              <h2 className="land-transport-title">הובלות יבשתיות</h2>
              <p className="land-transport-description">אנו בבר קרגו עובדים עם מגוון מובילים יבשתיים שפזורים מצפון עד דרום, בהתאם לסגמנטים שנדרשים.
                <br/>
כגון: הובלת סחורות מסוכנות, הובלת מזון, הובלות ישירות וכו.
<br/>
אנו נותנים פתרון להמכלות, אריזות וכל שירות נוסף שנדרש בזמן העמסה
<br/>
אנו שמים דגש על תכנון מקיף, ביטוח ושירות מקצועי.
</p>
         
              {/* <Link
                to="services"
                smooth={true}
                duration={500}
                className="land-transport-btn"
              >
                לעבור לשירותים
              </Link> */}
            </div>
        
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandTransport;
