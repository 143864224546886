import React from "react";
import { Link } from "react-scroll";
import "./Droppers.css";
import DroppersImage from "../../assets/Droppers.jpg";

const Droppers = () => {
  return (
    <div id="droppers" className="overlay">
      <div className="container">
        <div className="droppers-section">
          <div className="droppers-container">
            <div className="droppers-content"  dir="rtl">
              <h2 className="droppers-title">דרופים</h2>
              <p className="droppers-description">השירות למשלוחים בין מדינות נעשה מול סוכנים המתמחים בשחרורי מכס, הובלות יבשתיות, הובלות ימיות/ אווירית.
                <br/>
נהלי העבודה מול הסוכנים הינם: לכלול את כל העלויות, תהליך מהיר של שחרורי מכס ובאם עולה בעיה לתת לה מענה מיידי.
<br/>
אנו שמים דגש על מעקב צמוד מול הסוכנים, 24/7 ועדכון הלקוח בכל שלב בתהליך, למניעת עיכובים או עלויות נוספות.

      </p>
        
              {/* <Link
                to="services"
                smooth={true}
                duration={500}
                className="droppers-btn"
              >
                לעבור לשירותים
              </Link> */}
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Droppers;
