import React, { useRef } from "react";
import SocialHandles from "../../components/SocialHandles";
import "./Header.css";
import { FaArrowTurnDown } from "react-icons/fa6";
import { Link } from "react-scroll";
import  hero  from "../../assets/logo.png";
import WhyChooseUs from "../../components/WhyChooseUs";
import ImageNavigation from "../../components/ImageNavigation/ImageNavigation";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Header = () => {
  const container = useRef();
  useGSAP(
    () => {
      gsap
        .timeline({ delay: 0.0 })
        // .fromTo(
        //   [".heading", ".description"],
        //   { y: -30, opacity: 0 },
        //   { y: 0, opacity: 1, stagger: 0.5 }
        // )
        // .fromTo(
        //   [".social__handles", ".btn"],
        //   { x: -50, opacity: 0 },
        //   { x: 0, opacity: 1, stagger: 0.5 }
        // )
        // .fromTo(".image__contain", { x: 50, opacity: 0 }, { x: 0, opacity: 1 })
        .fromTo(
          [".why__choose__us", ".why__item"],
          { y: 100, opacity: 0 },
          { y: 0, opacity: 1, stagger: 0.1 }
        );
    },
    { scope: container }
  );

  return (
    <section id="header" className="blur__effect" ref={container} >
      <div className="overlay">
        <div className="container">
          {/* מבנה מסודר */}
          <div className="grid">
      
            <div className="image__contain">
              <img src={hero} alt="" />
            </div>
          </div>

          {/* רכיב התמונות למסכים גדולים */}
          <div className="image-navigation">
            <ImageNavigation />
          </div>

          {/* רכיב התמונות למסכים קטנים */}
          <div className="why__choose__us">
            <WhyChooseUs />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
