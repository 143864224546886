import React, { useRef } from "react";
import "./About.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import cragoIcon from "./aout-img.png";

gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const container = useRef();

  React.useEffect(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: container.current,
        start: "top 80%", // מתחיל האנימציה כשהרכיב נכנס למסך
        end: "bottom top", // מסיים כשהרכיב יוצא
        scrub: false,
      },
    });

    timeline
      .fromTo(
        ".about-title",
        { x: "100%", opacity: 0 }, // התחלה: הכותרת מחוץ למסך מימין
        { x: "0%", opacity: 1, duration: 2 } // כותרת גולשת פנימה
      )
      .fromTo(
        ".text-container p",
        { x: "100%", opacity: 0 }, // התחלה: הטקסט מחוץ למסך מימין
        { x: "0%", opacity: 1, duration: 1, stagger: 0.6 }, // הטקסט נכנס עם ריווח בין השורות
        "-=0.5" // מתחיל לפני סיום הכותרת
      )
      .fromTo(
        ".barcargo-icon",
        { y: "100%", opacity: 0 }, // התחלה: התמונה מחוץ למסך מלמטה
        { y: "0%", opacity: 1, duration: 1 } // התמונה נכנסת פנימה
      );
  }, []);

  return (
    <section id="about" dir="rtl" ref={container}>
      <div className="text-container">
        <h3 className="about-title">אודות</h3>
        <p>
        חברת בר קרגו נוסדה בשנת 2020, ע"י ריקי בר מנכלית החברה.
        <br/>
ריקי, בעלת ניסיון של 40 שנה בתחום שרשרת האספקה, המנווטת את הצוות ביד רמה למצוינות מול הלקוחות.
<br/>
במהלך השנים ריקי הובילה פרויקטים גדולים ומורכבים ואף שירתה לקוחות מהשורה הראשונה בייבוא ובייצוא מול ישראל.
<br/>
בחברת בר קרגו הלקוח הוא מעל הכל, ולכן אנו דואגים שהמשלוחים יתנהלו במקצועיות ויצירתיות, תוך הבנת צורכי הלקוח ותפירת פתרונות בזמן אמת.
<br/>
בחברתנו אנו עובדים מול סוכנים בכל העולם, כולל סוכני נישה, שמטפלים במשלוחים חריגים / פרויקטים על מנת לתת ללקוח מגוון אפשרויות.
בין לקוחותינו אפשר למצוא את עסקי הברזל, השקיה, פלסטיקה, חקלאות ועוד...
<br/>
אנו מתנהלים באופן טכנולוגי ואישי מול ספקי השירות: חב' ספנות, חב' תעופה, מובילים יבשתיים ועוד.
<br/>
הצוות מציע זמינות אמיתית של 24/7, תוך מתן מענה מידי ללקוח.
אנו מציעים חוויית שירות בוטיק עם מחירים הוגנים בשוק.

        </p>
      </div>

      <div className="barcargo-icon">
        <img className="cargo-icon" src={cragoIcon} alt="Cargo Icon" />
      </div>
    </section>
  );
};

export default About;
