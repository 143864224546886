import React from "react";
import "./WhyChooseUs.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Link } from "react-scroll";

// ייבוא התמונות
import AirmailShipping from "../../assets/AirmailShipping.jpg";
import SeaShipping from "../../assets/SeaShipping.png";
import CustomsClearance from "../../assets/CustomsClearance.jpg";
import Droppers from "../../assets/Droppers.jpg";
import LandTransport from "../../assets/LandTransport.jpg";
import project from "../../assets/project.jpg";

// רשימת התמונות
const images = [
  { src: AirmailShipping, alt: "שילוח אווירי", title: "שילוח אווירי", id: "air-shipping" },
  { src: SeaShipping, alt: "שילוח ימי", title: "שילוח ימי", id: "sea-shipping" },
  { src: CustomsClearance, alt: "שירותי מכס", title: "   שירותי מכס"  , id: "customs-clearance" },
  { src: Droppers, alt: "דרופרים", title: "דרופרים", id: "droppers" },
  { src: LandTransport, alt: "הובלה יבשתית", title: "הובלות ", id: "land-transport" },
  { src: project, alt: "פרויקטים", title: "פרויקטים", id: "project" },
];

const WhyChooseUs = () => {
  return (
    <div className="why__choose__us">
      <Swiper
        grabCursor={true}
        slidesPerView={2}
        loop={true}
        autoplay={{ delay: 3000 }}
        spaceBetween={15}
        speed={1000}
        modules={[Autoplay]}
        breakpoints={{
          350: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
        }}
      >
        {images.map((item, index) => (
          <SwiperSlide key={index} className="flex why__item">
            <img src={item.src} alt={item.alt} className="image" />
            <div className="content">
              <span className="title">{item.title}</span>
                  <Link
              to={item.id}
              smooth={true}
              duration={500}
              offset={-70} // התאמת המיקום לפי גובה הניווט
              className="title-link"
            >
              {item.title}
            </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default WhyChooseUs;
