import React from "react";
import { Link } from "react-scroll";
import "./AirShipping.css";
import AirShippingImage from "../../assets/AirmailShipping.jpg";

const AirShipping = () => {
  return (
    <div id="air-shipping" className="overlay">
      <div className="container">
        <div className="air-shipping-section">
          <div className="air-shipping-container">
            <div className="air-shipping-content">
              <h2 className="air-shipping-title">שילוח אווירי</h2>

              <ul className="air-shipping-description" dir="rtl">
  שילוח אווירי:  
  ישנם משלוחים שהובלה אווירית הכרחית כשזה תלוי בדחיפות המשלוח, הגעה ליעדים מרוחקים, בטיחות המשלוח וחיסכון בעלויות.<br /><br />
  אנו בבר קרגו נתאים את הפתרון המושלם בשבילך במינימום זמן, חסכון כספי ובמעקב צמוד עד להגעת המשלוח ליעדו.
  <br /><br />
 <li>ניתן הצעת מחיר שכוללת את כל העלויות (במטרה להקל על היבואן / יצואן)
 </li>
 <li>נבדוק כל הצעת מחיר מול כמה ספקי שירות: סוכנים, חברות ספנות, חברות תעופה.
 </li>
 <li>נעקוב ונעדכן את הלקוח בזמן אמת על סטטוס המשלוח עד הגעתו באופן סופי ליעד.</li>
 אנו זמינים 24/7 לכל פניה / שאלה מהלקוח, הסוכנים או ספקי השירות.
 <br />
</ul>


              {/* <Link
                to="services"
                smooth={true}
                duration={500}
                className="air-shipping-btn"
              >
                לעבור לשירותים
              </Link> */}
            </div>
       
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirShipping;
